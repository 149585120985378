import { useState } from "react"
import { Redirect } from "react-router-dom"

import axios, { AxiosError } from "axios"

import {
  CreateAccount,
  ICreateAccountSubmit,
} from "src/components/Signup/CreateAccount"
import { API_DEFAULT } from "src/constants/minutApi"
import { passwordSignin } from "src/context/auth/signIn"
import { useAppData } from "src/context/useAppData"
import { redirectToAuthService } from "src/data/auth/auth"
import { storedLocation } from "src/data/auth/authStorage"
import { useFetchInvitationInfo } from "src/data/organizations/queries/inviteQueries"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useFlags } from "src/hooks/useFlags"
import { getInviteCodeFromUrl } from "src/router/organizationRoutes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { sleep } from "src/utils/genericUtil"
import { browserLocale } from "src/utils/l10n"
import { debug } from "src/utils/logger"

function createAccount({
  name,
  email,
  password,
  subscribe_newsletter,
}: {
  name: string
  email: string
  password: string
  subscribe_newsletter: boolean
}) {
  const response = axios.post(`${API_DEFAULT}/users`, {
    fullname: name,
    email: email,
    password: password,
    subscribe_newsletter: subscribe_newsletter,
    preferred_language: browserLocale(),
    client_id: import.meta.env.REACT_APP_CLIENT_ID,
    client_secret: import.meta.env.REACT_APP_CLIENT_SECRET,
    create_personal_org: false,
  })

  return response
}

export function Signup() {
  const { oauthEnabled } = useFlags()
  const inviteCode = getInviteCodeFromUrl()
  const location = useAppLocation()
  const { ready, authState } = useAppData()
  const { navigate } = useRouter()

  const [creatingAccount, setCreatingAccount] = useState(false)
  const [error, setError] = useState<boolean | string>(false)

  const fetchInvitationInfo = useFetchInvitationInfo({
    inviteCode: inviteCode || "",
    options: {
      enabled: !!inviteCode,
    },
  })

  const invitationinfo = fetchInvitationInfo.data

  const handleSubmit = async ({
    name,
    email,
    password,
    subscribe_newsletter,
    honey,
  }: ICreateAccountSubmit) => {
    setCreatingAccount(true)
    if (!!honey) {
      await sleep(180 * 1000)
      setCreatingAccount(false)
      return
    }

    try {
      await createAccount({ name, email, password, subscribe_newsletter })
      debug.log("Account created, signing in...")
      passwordSignin({
        username: email,
        password,
        onSignedIn: ({ authorization }) =>
          authState.setAuthorization(authorization),
      })
    } catch (e) {
      const err = e as AxiosError
      if (err.response && err.response.status === 409) {
        setError("An account with the provided email already exists.")
      } else {
        setError("An error occurred, we couldn't create the account.")
      }
      setCreatingAccount(false)
    }
  }

  if (ready) {
    debug.log("Signed in => dashboard")
    navigate({ ...location, ...Routes.Dashboard.location() })
    return null
  }

  if (fetchInvitationInfo.isInitialLoading) {
    return (
      <LoadingScreen
        debugInfo={<div>Fetching invitation information ...</div>}
      />
    )
  }

  if (invitationinfo?.existing_user) {
    return <Redirect to={{ ...location, ...Routes.Login.location() }} />
  }

  if (oauthEnabled) {
    // we need to store the location since you might land here after an invite link
    storedLocation.set(location)
    // If enabled by accident, open http://localhost:8001?oauthEnabled=off
    redirectToAuthService(location)
    return (
      <LoadingScreen
        debugInfo={<div>Redirecting to OAuth login page...</div>}
      />
    )
  }

  return (
    <CreateAccount
      creatingAccount={creatingAccount}
      onSubmit={handleSubmit}
      error={error}
      prefillEmail={invitationinfo?.invitation_email}
    />
  )
}
